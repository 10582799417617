/* Bar style */
.ol-control.ol-bar
{	left: 50%;
	min-height: 1em;
	min-width: 1em;
	position: absolute;
	top: 0.5em;
	transform: translate(-50%,0);
	-webkit-transform: translate(-50%,0);
}

/* Hide subbar when not inserted in a parent bar */
.ol-control.ol-toggle .ol-option-bar
{	display: none;
}

/* Default position for controls */
.ol-control.ol-bar .ol-bar
{	position: static;
}
.ol-control.ol-bar .ol-control
{	position: relative;
	top: auto;
	left:auto;
	right:auto;
	bottom: auto;
	display: inline-block;
	vertical-align: middle;
	background: none;
	padding: 0;
	margin: 0;
	transform: none;
	-webkit-transform: none;
}
.ol-control.ol-bar .ol-bar
{	position: static;
}
.ol-control.ol-bar .ol-control button
{	margin:2px 1px;
}

/* Positionning */
.ol-control.ol-bar.ol-left
{	left: 0.5em;
	top: 50%;
	transform: translate(0px, -50%);
}
.ol-control.ol-bar.ol-left .ol-control
{	display: block;
}

.ol-control.ol-bar.ol-right
{	left: auto;
	right: 0.5em;
	top: 50%;
	transform: translate(0px, -50%);
}
.ol-control.ol-bar.ol-right .ol-control
{	display: block;
}

.ol-control.ol-bar.ol-bottom
{	top: auto;
	bottom: 0.5em;
}

.ol-control.ol-bar.ol-top.ol-left,
.ol-control.ol-bar.ol-top.ol-right
{	top: 4.5em;
	transform:none;
}
.ol-touch .ol-control.ol-bar.ol-top.ol-left,
.ol-touch .ol-control.ol-bar.ol-top.ol-right
{	top: 5.5em; 
}
.ol-control.ol-bar.ol-bottom.ol-left,
.ol-control.ol-bar.ol-bottom.ol-right
{	top: auto;
	bottom: 0.5em;
	transform:none;
}

/* Group buttons */
.ol-control.ol-bar.ol-group
{	margin: 1px 1px 1px 0;
}
.ol-control.ol-bar.ol-right .ol-group,
.ol-control.ol-bar.ol-left .ol-group
{	margin: 1px 1px 0 1px;
}

.ol-control.ol-bar.ol-group button
{	border-radius:0;
	margin: 0 0 0 1px;
}
.ol-control.ol-bar.ol-right.ol-group button,
.ol-control.ol-bar.ol-left.ol-group button,
.ol-control.ol-bar.ol-right .ol-group button,
.ol-control.ol-bar.ol-left .ol-group button
{	margin: 0 0 1px 0;
}
.ol-control.ol-bar.ol-group .ol-control:first-child > button
{	border-radius: 5px 0 0 5px;
}
.ol-control.ol-bar.ol-group .ol-control:last-child > button
{	border-radius: 0 5px 5px 0;
}
.ol-control.ol-bar.ol-left.ol-group .ol-control:first-child > button,
.ol-control.ol-bar.ol-right.ol-group .ol-control:first-child > button,
.ol-control.ol-bar.ol-left .ol-group .ol-control:first-child > button,
.ol-control.ol-bar.ol-right .ol-group .ol-control:first-child > button
{	border-radius: 5px 5px 0 0;
}
.ol-control.ol-bar.ol-left.ol-group .ol-control:last-child > button,
.ol-control.ol-bar.ol-right.ol-group .ol-control:last-child > button,
.ol-control.ol-bar.ol-left .ol-group .ol-control:last-child > button,
.ol-control.ol-bar.ol-right .ol-group .ol-control:last-child > button
{	border-radius: 0 0 5px 5px;
}

/* */
.ol-control.ol-bar .ol-rotate
{	opacity:1;
	visibility: visible;
}
.ol-control.ol-bar .ol-rotate button
{	display: block
}

/* Active buttons */
.ol-control.ol-bar .ol-toggle.ol-active > button
{	background: rgba(60, 136, 0, 0.7)
}
.ol-control.ol-bar .ol-toggle.ol-active button:hover
{	background: rgba(60, 136, 0, 0.7)
}

/* Subbar toolbar */
.ol-control.ol-bar .ol-control.ol-option-bar 
{	display: none;
	position:absolute;
	top:100%;
	left:0;
	margin: 5px 0;
	border-radius: 0;
	background: rgba(255,255,255, 0.8);
	/* border: 1px solid rgba(0, 60, 136, 0.5); */
	box-shadow: 0 0 0 1px rgba(0, 60, 136, 0.5), 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.ol-control.ol-bar .ol-option-bar:before
{	content: "";
	border: 0.5em solid transparent;
	border-color: transparent transparent rgba(0, 60, 136, 0.5);
	position: absolute;
	bottom: 100%;
	left: 0.3em;
}

.ol-control.ol-bar .ol-option-bar .ol-control
{	display: table-cell;
}
.ol-control.ol-bar .ol-control .ol-bar
{	display: none;
}
.ol-control.ol-bar .ol-control.ol-active > .ol-option-bar 
{	display: block;
}

.ol-control.ol-bar .ol-control.ol-collapsed ul
{	display: none;
}

.ol-control.ol-bar .ol-control.ol-text-button > button:hover,
.ol-control.ol-bar .ol-control.ol-text-button > button
{	background: none;
	color: rgba(0, 60, 136, 0.5);
	width: auto;
	min-width: 1.375em;
	margin: 0;
}

.ol-control.ol-bar .ol-control.ol-text-button
{	font-size:0.9em;
	border-left: 1px solid rgba(0, 60, 136, 0.8);
	border-radius: 0;
}
.ol-control.ol-bar .ol-control.ol-text-button:first-child
{	border-left:0;
}
.ol-control.ol-bar .ol-control.ol-text-button button
{	padding:0 0.3em;
	font-weight: normal;
	height:1.4em;
}
.ol-control.ol-bar .ol-control.ol-text-button button:hover
{	color: rgba(0, 60, 136, 1);
}

.ol-control.ol-bar.ol-bottom .ol-option-bar
{	top: auto;
	bottom: 100%;
}
.ol-control.ol-bar.ol-bottom .ol-option-bar:before
{	border-color: rgba(0, 60, 136, 0.5) transparent transparent ;
	bottom: auto;
	top: 100%;
}

.ol-control.ol-bar.ol-left .ol-option-bar
{	left:100%;
	top: 0;
	bottom: auto;
	margin: 0 5px;
}
.ol-control.ol-bar.ol-left .ol-option-bar:before
{	border-color: transparent rgba(0, 60, 136, 0.5) transparent transparent;
	bottom: auto;
	right: 100%;
	left: auto;
	top: 0.3em;
}
.ol-control.ol-bar.ol-right .ol-option-bar
{	right:100%;
	left:auto;
	top: 0;
	bottom: auto;
	margin: 0 5px;
}
.ol-control.ol-bar.ol-right .ol-option-bar:before
{	border-color: transparent transparent transparent rgba(0, 60, 136, 0.5);
	bottom: auto;
	left: 100%;
	top: 0.3em;
}

.ol-control.ol-bar.ol-left .ol-option-bar .ol-option-bar,
.ol-control.ol-bar.ol-right .ol-option-bar .ol-option-bar
{	top: 100%;
	bottom: auto;
	left: 0.3em;
	right: auto;
	margin: 5px 0;
}
.ol-control.ol-bar.ol-right .ol-option-bar .ol-option-bar
{	right: 0.3em;
	left: auto;
}
.ol-control.ol-bar.ol-left .ol-option-bar .ol-option-bar:before,
.ol-control.ol-bar.ol-right .ol-option-bar .ol-option-bar:before
{	border-color: transparent transparent rgba(0, 60, 136, 0.5);
	bottom: 100%;
	top: auto;
	left: 0.3em;
	right: auto;
}
.ol-control.ol-bar.ol-right .ol-option-bar .ol-option-bar:before
{	right: 0.3em;
	left: auto;
}
