.ol-control.ol-overview
{	position: absolute;
	left: 0.5em;
	text-align: left;
	bottom: 0.5em;
}

.ol-control.ol-overview .panel
{	display:block;
	width:150px;
	height:150px;
	margin:2px;
	background-color:#fff;
	border:1px solid #369;
	cursor: pointer;
}

.ol-overview:not(.ol-collapsed) button
{	position:absolute;
	bottom:2px;
	left:2px;
	z-index:2;
}

.ol-control.ol-overview.ol-collapsed .panel
{	display:none;
}

.ol-overview.ol-collapsed button:before
{	content:'\00bb';
}
.ol-overview button:before
{	content:'\00ab';
}


.ol-control-right.ol-overview
{	left: auto;
	right: 0.5em;
}
.ol-control-right.ol-overview:not(.ol-collapsed) button
{	left:auto;
	right:2px;
}
.ol-control-right.ol-overview.ol-collapsed button:before
{	content:'\00ab';
}
.ol-control-right.ol-overview button:before
{	content:'\00bb';
}

.ol-control-top.ol-overview
{	bottom: auto;
	top: 5em;
}
.ol-control-top.ol-overview:not(.ol-collapsed) button
{	bottom:auto;
	top:2px;
}
